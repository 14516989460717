import React, { FC } from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';

import { BaseComponentProps, ProfileLayout } from '../../../../types';

const DesktopResponsiveWidget: FC<BaseComponentProps> = loadable(() => {
  return import(
    /* webpackChunkName: "DesktopResponsiveRootWidget" */ './DesktopResponsiveWidget'
  );
});

const DesktopNonResponsiveWidget: FC<BaseComponentProps> = loadable(() => {
  return import(
    /* webpackChunkName: "DesktopNonResponsiveRootWidget" */ './DesktopNonResponsiveWidget'
  );
});

const DesktopWidget: FC<BaseComponentProps> = (props) => {
  return props.computed.profileLayout === ProfileLayout.FullWidth ? (
    <DesktopResponsiveWidget {...props} />
  ) : (
    <DesktopNonResponsiveWidget {...props} />
  );
};

export default DesktopWidget;
